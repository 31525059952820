import React from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import SEO from "../components/seo";
import styled, { createGlobalStyle } from "styled-components";

// import test from "../data/cover-letter.md";

const date = moment().format("D MMMM YYYY");
// const role = "Software Engineer";
const role = "Senior Frontend Developer";
const company = "Well Revolution";

export const CVStyle = createGlobalStyle`
  @page {
    background: #e3e2dd;
    size: A4;
    margin: 7mm;
    /* @bottom-right {
       content: counter(page) " / " counter(pages);
    } */
  }
  body {
    background: #e3e2dd;
  }
`;

const StyledCL = styled.div`
  color: #496447;
  padding: 0.8cm 1cm 0;
  font-family: "Montserrat", sans-serif;
  font-size: 9pt;
  line-height: 13pt; /* h1 { margin: 0 0 0.3cm; font-size: 10pt; } h2 { margin: 0 0 0.3cm; font-size: 13pt; } h3 { font-size: 12pt; margin: 0 0 0.5cm; :not(:first-child) { margin-top: 1cm; } } h4 { margin: 0 0 0.5mm; font-size: 9pt; font-weight: 600; } h5 { margin: 0 0 1mm; font-size: 9pt; font-weight: 600; } a { text-decoration: none; color: inherit; } p { margin: 0 0 1cm; font-size: 11pt; line-height: 16pt; font-weight: 600; } ul { margin-top: 1mm; padding-left: 4mm; } li { margin-bottom: 1mm; } strong { font-weight: 600; } header { margin-bottom: 0.9cm; font-size: 9pt; line-height: 15pt; } .right { text-align: right; } main { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1cm; } */
`;

const CL = () => (
  <StyledCL>
    <CVStyle />
    <SEO
      title="cover-letter"
      meta={[
        {
          name: "robots",
          content: "noindex, nofollow",
        },
      ]}
    />

    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>

    <main>
      <br />
      <br />
      16A Maungarei Rd
      <br />
      Remuera
      <br />
      Auckland
      <br />
      <br />
      {date}
      <br />
      <br />
      <br />
      <br />
      <p>Dear {company},</p>
      <br />
      <p>
        I'm writing to express my interest in the {role} role. I'm a highly
        experienced Frontend Engineer based in Auckland with 15+ years
        experience in the web industry – coding beautiful, functional and fully
        responsive user experiences using React and CSS is what I do everyday
        and it's what I'm passionate about.
      </p>
      {/* <p>
        At the start of Alert Level 4, I saw the need for a better
        representation of the Covid-19 data from the Ministry of Health, which
        prompted me to create covid19map.nz - New Zealand’s first live map and
        infographics of Covid-19 cases. This open source project was created
        over a weekend and used technologies such as React, Styled Components
        and Leaflet.
      </p> */}
      {/* <p>
        I'm very excited to have found this opportunity at TVNZ. The prospect of
        extending my skillset from front-end development to fullstack, and to
        work on products that would touch millions of lives of New Zealanders is
        the thing that would get me excited to go to work every morning.
      </p> */}
      {/* <p>
        I'm very excited to have found this opportunity at NZTE. The prospect of
        extending my skillset from front-end development to full stack, and to
        work for an organisation that would make a difference for the good of
        New Zealand is the thing that would get me excited to go to work every
        morning.
      </p> */}
      {/* <p>
        I'm very excited to have found this opportunity at SKY. The prospect of
        working on products that would touch millions of lives of New Zealanders
        is the thing that would get me excited to go to work every morning.
      </p> */}
      {/* <p>
        I'm very excited to have found this opportunity at SKY. The prospect of
        working on products that would touch millions of lives of New Zealanders
        is the thing that would get me excited to go to work every morning.
      </p> */}
      {/* <p>
        I'm very excited to have found this opportunity at IMAGR. The prospect of
        working on something that will revolutionise modern retail and make life
        easier for everyday people is the thing that would get me excited to go
        to work every morning.
      </p> */}
      {/* <p>
        I'm currently self-employed, and have been working for the last four
        years across a range of clients and contracts. My self-motivated and
        flexible approach, as well as strong communication skills have allowed
        me to collaborate effectively with designers, project managers and
        client stakeholders in a variety of team environments.
      </p> */}
      <p>
        In my current role at Atlassian, I've further developed my software
        engineering skills, and have worked with designers and developers across
        Australia and USA. I'm proud to have contributed to world-class products
        like Jira, Confluence and Trello.
      </p>
      {/* <p>
        For the last four years I've been working across a range of clients and
        contracts. My self-motivated and flexible approach, as well as strong
        communication skills have allowed me to collaborate effectively with
        designers, project managers and client stakeholders in a variety of team
        environments.
      </p> */}
      {/* <p>
        In my most recent role, I am a senior front-end developer in a global
        SaaS company, working with large teams on a complex cloud based
        application in React, SASS and Typescript.
      </p> */}
      <p>
        I am a keen and fast learner, I thrive on new challenges and am
        constantly upskilling to further myself and extend my ability to create
        the best web experiences possible.
      </p>
      {/* <p>
        In addition to my experiences outlined in my CV, I've also worked on
        some personal projects - A (very much work in progress) typing game
        written in Phaser (https://dixoncheng.com/pokemon/), and a React Native
        app for tracking expenses.
      </p> */}
      <p>
        I'm looking for the next challenge and I am excited by the prospect of
        working alongside the talented team at {company}. I am confident my
        extensive technical experience, attention to detail and positive work
        ethic will see me as a really good fit for this role.
      </p>
      <p>
        Thank you for your time and consideration. I look forward to discussing
        this opportunity further with you.
      </p>
      <br />
      <p>Regards,</p>
      <p>
        Dixon Cheng
        <br />
        <br />
        dixoncheng.com
        <br />
        github.com/dixoncheng
        <br />
        linkedin.com/in/dixon-cheng
      </p>
    </main>
  </StyledCL>
);

export default CL;

// I've been involved in a number of projects using Vue.js over the years. Most notably a frontend build for Mazda NZ. The website was built using the Drupal CMS, in tightly coupled php templates. My job was to modernise the frontend using Vue.js, building components from scratch, and decoupling the frontend from the backend. The result was a much better user experience while minimising the backend load.
// In my current role at Atlassian, I've further developed my frontend skills, and have worked with designers and developers across Australia and USA. I'm proud to have contributed to world-class products like Jira, Confluence and Trello.
// Thank you for your time and consideration. I look forward to discussing this opportunity further with you.
